import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { ParsedTaskObject } from "@redwit-commons/object/task";
import {
  AdminOrganizationAdmin,
  AdminOrganizationSummary,
  AdminUser,
  AdminWorkspace,
} from "@goono-commons/v3/goonoAdmin";
import { SelectedWorkspace, WorkspaceSummaryDto } from "@utils/types/workspace";
import { AdminWorkspaceMemberWithLogData } from "@goono-commons/v3/goonoAdmin";
import { WorkspacePlanOptionType } from "@utils/types/selectOptions";
import { WorkspacesWithMembers } from "@utils/types/modal";
import { Workspace } from "@goono-commons/v3/workspace";
import { GetAllWorkspacesParams } from "@react-query/api/workspace";

export enum ModalStateStatus {
  INIT = "Modal::INIT",
}

export enum ModalActionKind {
  TRY_OPEN = "ModalAction::TRY_OPEN",
  TRY_CLOSE = "ModalAction::TRY_CLOSE",
  TRY_CLOSE_ALL = "ModalAction::TRY_CLOSE_ALL",
}

// 여기서 modal type 추가
export enum ModalType {
  MANAGE_USER = "ModalType::MANAGE_USER",
  USER_INFO = "ModalType::USER_INFO",
  SEND_EMAIL = "ModalType::SEND_EMAIL",
  DELETE_WORKSPACE = "ModalType::DELETE_WORKSPACE",
  DELETE_WORKSPACE_MEMBER = "ModalType::DELETE_WORKSPACE_MEMBER",
  DELETE_USER = "ModalType::DELETE_USER",
  UPDATE_OWNER = "ModalType::UPDATE_OWNER",
  UPDATE_SINGLE_WORKSPACE_PLAN = "ModalType::UPDATE_SINGLE_WORKSPACE_PLAN",
  BULK_UPDATE_WORKSPACE_PLAN = "ModalType::BULK_UPDATE_WORKSPACE_PLAN",
  BULK_UPDATE_WORKSPACES_MEMBER = "ModalType::BULK_UPDATE_WORKSPACES_MEMBER",
  SET_MEMBER_PERMISSIONS = "ModalType::SET_MEMBER_PERMISSIONS",
  DELETE_OWNER_WARNING = "ModalType::DELETE_OWNER_WARNING",
  DELETE_ORGANIZATION = "ModalType::DELETE_ORGANIZATION",
  MANAGE_ORGANIZATION_ADMIN = "ModalType::MANAGE_ORGANIZATION_ADMIN",
  MANAGE_ORGANIZATION_WORKSPACE = "ModalType::MANAGE_ORGANIZATION_WORKSPACE",
  DELETE_ORGANIZATION_ADMIN = "ModalType::DELETE_ORGANIZATION_ADMIN",
  CREATE_ORGANIZATION_ADMIN = "ModalType::CREATE_ORGANIZATION_ADMIN",
  UPDATE_ORGANIZATION_ADMIN = "ModalType::UPDATE_ORGANIZATION_ADMIN",
  DELETE_WORKSPACE_MEMBER_ALERT = "ModalType::DELETE_WORKSPACE_MEMBER_ALERT",
  RECONFIRM = "ModalType::RECONFIRM",
  BULK_UPDATE_HISTORY = "ModalType::BULK_UPDATE_HISTORY",
  SET_DOWNLOAD_DATE = "ModalType::SET_DOWNLOAD_DATE",
}

export type ModalError = never;

export type ManageUserModalProps = {
  readonly ownWorkspace?: AdminWorkspace & {
    members: AdminWorkspaceMemberWithLogData[];
  };
  readonly workspaceSummary?: WorkspaceSummaryDto;
  readonly planLevel?: WorkspacePlanOptionType;
  readonly workspaceMembers?: AdminWorkspaceMemberWithLogData[];
  readonly onConfirm: (params: {
    workspaceId?: string;
    assignedMembers?: AdminWorkspaceMemberWithLogData[];
    resignedMembers?: AdminWorkspaceMemberWithLogData[];
  }) => void;
};

export type UserInfoModalProps = {
  readonly id: string;
  readonly pendingUsers: ParsedTaskObject[];
};

export type SendEmailModalProps = {
  readonly userInfo: AdminUser;
  readonly pendingUsers: ParsedTaskObject[];
};

export type DeleteWorkspaceModalProps = {
  readonly targetWorkspace: AdminWorkspace;
};

export type DeleteWorkspaceMemberModalProps = {
  readonly targetWorkspace: AdminWorkspace;
  readonly targetMember: AdminWorkspaceMemberWithLogData;
};

export type DeleteUserModalProps = {
  readonly targetUser: AdminUser;
};

export type UpdateOwnerModalProps = {
  readonly targetUser: AdminUser;
  readonly service: "researchNote" | "documentBox";
};

export type RenewSingleWorkspacePlanModalProps = {
  readonly targetWorkspace: SelectedWorkspace & {
    name: string;
    institutionId?: string;
  };
  readonly onSuccess: () => void;
  readonly onFail: () => void;
};

export type BulkRenewWorkspacePlanModalProps = {
  readonly workspaceIdFilters: WorkspaceIdFilter;
  readonly workspaceListFilter: GetAllWorkspacesParams;
};

type WorkspaceIdFilter = {
  workspaceIds: string[];
  excludeIds: string[];
};

export type BulkUpdateWorkspacesMemberModalProps = {
  readonly workspaceIdFilters: WorkspaceIdFilter;
  readonly workspaceListFilter: GetAllWorkspacesParams;
  readonly workspaces: WorkspacesWithMembers;
};

export type SetMemberPermissionsModalProps = {
  readonly workspaceIdFilters: WorkspaceIdFilter;
  readonly workspaceListFilter: GetAllWorkspacesParams;
  readonly selectedMembers: AdminUser[];
};

export type DeleteOwnerWarningModalProps = {
  readonly confirm: () => void;
};

export type DeleteOrganizationModalProps = {
  readonly organization: AdminOrganizationSummary;
};

export type ManageOrganizationAdminModalProps = {
  readonly organizationAdmins?: AdminOrganizationAdmin[];
  readonly onConfirm: (newAdmins: AdminOrganizationAdmin[]) => void;
};

export type ManageOrganizationWorkspaceModalProps = {
  readonly organizationWorkspaces?: Workspace[];
  readonly onConfirm: (newWorkspaces: Workspace[]) => void;
};

export type DeleteOrganizationAdminModalProps = {
  readonly organizationAdmin: AdminOrganizationAdmin;
};

export type UpdateOrganizationAdminModalProps = {
  readonly organizationAdmin: AdminOrganizationAdmin;
};

export type DeleteWorkspaceMemberAlertModalProps = {
  readonly callback: () => void;
};

export type ReconfirmModalProps = {
  readonly title: string;
  readonly bodyTitle?: string;
  readonly bodyMsg: string;
  readonly confirmMsg: string;
  readonly reconfirmInputOption?: {
    answer: string;
  };
  readonly onConfirm: (() => void) | (() => Promise<void>);
};

export type SetDownloadDateModalProps = {
  readonly title: string;
  readonly onConfirm: (params: { from: string; to: string }) => void;
};

type GenericModalInfo<T = ModalType, U = any> = {
  readonly type: T;
} & U;

export type ModalInfo =
  | GenericModalInfo<ModalType.MANAGE_USER, ManageUserModalProps>
  | GenericModalInfo<ModalType.USER_INFO, UserInfoModalProps>
  | GenericModalInfo<ModalType.SEND_EMAIL, SendEmailModalProps>
  | GenericModalInfo<ModalType.DELETE_WORKSPACE, DeleteWorkspaceModalProps>
  | GenericModalInfo<
      ModalType.DELETE_WORKSPACE_MEMBER,
      DeleteWorkspaceMemberModalProps
    >
  | GenericModalInfo<ModalType.DELETE_USER, DeleteUserModalProps>
  | GenericModalInfo<ModalType.UPDATE_OWNER, UpdateOwnerModalProps>
  | GenericModalInfo<
      ModalType.UPDATE_SINGLE_WORKSPACE_PLAN,
      RenewSingleWorkspacePlanModalProps
    >
  | GenericModalInfo<
      ModalType.BULK_UPDATE_WORKSPACE_PLAN,
      BulkRenewWorkspacePlanModalProps
    >
  | GenericModalInfo<
      ModalType.BULK_UPDATE_WORKSPACES_MEMBER,
      BulkUpdateWorkspacesMemberModalProps
    >
  | GenericModalInfo<
      ModalType.SET_MEMBER_PERMISSIONS,
      SetMemberPermissionsModalProps
    >
  | GenericModalInfo<
      ModalType.DELETE_OWNER_WARNING,
      DeleteOwnerWarningModalProps
    >
  | GenericModalInfo<
      ModalType.DELETE_ORGANIZATION,
      DeleteOrganizationModalProps
    >
  | GenericModalInfo<
      ModalType.MANAGE_ORGANIZATION_ADMIN,
      ManageOrganizationAdminModalProps
    >
  | GenericModalInfo<
      ModalType.MANAGE_ORGANIZATION_WORKSPACE,
      ManageOrganizationWorkspaceModalProps
    >
  | GenericModalInfo<
      ModalType.DELETE_ORGANIZATION_ADMIN,
      DeleteOrganizationAdminModalProps
    >
  | GenericModalInfo<ModalType.CREATE_ORGANIZATION_ADMIN, {}>
  | GenericModalInfo<
      ModalType.UPDATE_ORGANIZATION_ADMIN,
      UpdateOrganizationAdminModalProps
    >
  | GenericModalInfo<
      ModalType.DELETE_WORKSPACE_MEMBER_ALERT,
      DeleteWorkspaceMemberAlertModalProps
    >
  | GenericModalInfo<ModalType.RECONFIRM, ReconfirmModalProps>
  | GenericModalInfo<ModalType.BULK_UPDATE_HISTORY, {}>
  | GenericModalInfo<ModalType.SET_DOWNLOAD_DATE, SetDownloadDateModalProps>;

export type ModalState = {
  readonly status: ModalStateStatus.INIT;
  readonly openedModalList: ModalInfo[];
};

export type ModalAction =
  | ({
      readonly kind: ModalActionKind.TRY_OPEN;
    } & ModalInfo)
  | {
      readonly kind: ModalActionKind.TRY_CLOSE;
    }
  | {
      readonly kind: ModalActionKind.TRY_CLOSE_ALL;
    };

const smid = "MODAL_STATE_MACHINE3";
export type ModalStateMachineType = StateMachine3<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>;

export const modalStateMachine: ModalStateMachineType = new StateMachine3<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>(smid, { status: ModalStateStatus.INIT, openedModalList: [] }, [
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_OPEN
  ),
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_CLOSE
  ),
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_CLOSE_ALL
  ),
]);

export type DispatchModalAction = Dispatch<
  StateMachineAction<
    ModalStateStatus,
    ModalState,
    ModalActionKind,
    ModalAction,
    ModalError
  >
>;

export default mkReducer<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>(modalStateMachine);

export const doModalAction = (
  dispatch: DispatchModalAction,
  nextAction: ModalAction,
  onResolve: () => void = () => {},
  onReject: (err: ModalError | InternalError) => void = () => {}
) => {
  dispatch(modalStateMachine.newTryAction(nextAction, onResolve, onReject));
};

export const doModalActionAsync = (
  dispatch: DispatchModalAction,
  nextAction: ModalAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(modalStateMachine.newTryAction(nextAction, resolve, reject));
  });
};

export const resetModal = (dispatch: DispatchModalAction) => {
  dispatch(modalStateMachine.newResetAction());
};
