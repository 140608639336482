import T from "@redwit-commons/utils/typecheck";

export type ArrayResponse<T> = {
  total: number;
  results: T[];
};

export const mkArrayResponseSchema = (arrayItemSchema: T): T => {
  return T.object()
    .addField("total", T.integer())
    .addField("results", T.array(arrayItemSchema));
};
