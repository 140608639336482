import T from "@redwit-commons/utils/typecheck";
import { IpfsData, IpfsDataSchema } from "../api/object/ipfs";
import { ArrayResponse, mkArrayResponseSchema } from "./common";

export type Institution = {
  id: string;
  name: string;
  description?: string;
  workspaceCount: number;
  createdAt: string;
};

export type OGetAllInstitutions = ArrayResponse<Institution>;

export const InstitutionSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("description", T.string(), false)
  .addField("workspaceCount", T.integer())
  .addField("createdAt", T.string());

export const validateOGetInstitution =
  T.mkValidator<Institution>(InstitutionSchema);

export const OGetAllInstitutionsSchema = mkArrayResponseSchema(
  InstitutionSchema.clone()
);

export const validateOGetAllInstitutions = T.mkValidator<OGetAllInstitutions>(
  OGetAllInstitutionsSchema
);

export type InstitutionSummary = {
  id: string;
  name: string;
};

export const InstitutionSummarySchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string());

export type InstitutionCommentUserInfo = {
  id: string;
  name: string;
  email: string;
  avatar: IpfsData;
};

export type InstitutionComment = {
  id: string;
  text: string;
  pinnedUser?: InstitutionCommentUserInfo;
  institutionId: string;
  creator?: InstitutionCommentUserInfo;
  createdAt: string;
};

export const InstitutionCommentUserInfoSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("email", T.string())
  .addField("avatar", IpfsDataSchema.clone());

export const InstitutionCommentSchema = T.object()
  .addField("id", T.string())
  .addField("text", T.string())
  .addField("pinnedUser", InstitutionCommentUserInfoSchema.clone(), false)
  .addField("institutionId", T.string())
  .addField("creator", InstitutionCommentUserInfoSchema.clone(), false)
  .addField("createdAt", T.string());

export type OGetAllInstitutionComments = ArrayResponse<InstitutionComment>;

export const OGetAllInstitutionCommentsSchema = mkArrayResponseSchema(
  InstitutionCommentSchema.clone()
);

export const validateOGetAllInstitutionComments =
  T.mkValidator<OGetAllInstitutionComments>(OGetAllInstitutionCommentsSchema);
