import React from "react";
import RoutingManager from "./routes/RoutingManager";
import { QueryClientProvider, QueryClient } from "react-query";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { changeEnv, ENV } from "@goono-react-commons/services/api";
import ContainerProvider from "./redux/containers/ContainerProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1, // 요청 실패 시 재요청 횟 수
      refetchOnWindowFocus: false, // widnow가 다시 포커스 되었을 때 재요청을 보낼 것인지
    },
  },
});

let didInit = false;

export default function App() {
  React.useEffect(() => {
    if (!didInit) {
      didInit = true;
      const test_mode = process.env.REACT_APP_TEST_ENABLED?.toLocaleLowerCase();
      const app_api_server = process.env.REACT_APP_API_SERVER;
      const app_ipfs_api_server = process.env.REACT_APP_IPFS_API_SERVER;

      if (
        typeof test_mode === "string" &&
        (test_mode === "1" || test_mode === "y")
      ) {
        changeEnv("staging");
        if (typeof app_api_server === "string" && app_api_server.length !== 0)
          ENV.API_SERVER = app_api_server;
        if (
          typeof app_ipfs_api_server === "string" &&
          app_ipfs_api_server.length !== 0
        )
          ENV.IPFS_API_SERVER = app_ipfs_api_server;
        console.log("ENV CHANGED: ", ENV);
      }
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ContainerProvider>
            <RoutingManager />
          </ContainerProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}
